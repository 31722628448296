<template>
  <div class="app-container">
    <div class="main-body">
      <el-row class="call-title">
        <el-col :span="24">
          <i class="lc-tms-iconyunweiguanli icon-cls icon-cls-green" />
          <span class="title">土方单位管理</span>
        </el-col>
      </el-row>
      <div ref="searchBar" class="form-cls">
        <el-form @heightChange="_getTableHeight()">
          <el-row>
            <el-col :span="6">
              <el-form-item label="冻结状态:">
                <el-select
                  clearable
                  multipart
                  @keyup.enter.native="queryList"
                  v-model="queryParams.status"
                  placeholder="请选择"
                  class="input-cls input-width-6"
                  size="small"
                >
                  <el-option v-for="item in statusFlags" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="土方单位:">
                <el-input
                  v-model.trim="queryParams.name"
                  clearable
                  type="text"
                  size="small"
                  class="input-cls input-width-6"
                  placeholder="请输入内容"
                  @keyup.enter.native="queryList"
                />
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item>
                <el-button plain size="small" type="primary" icon="el-icon-search" @click="queryList"> 查询 </el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <!-- </search-bar> -->
      </div>
      <div class="main-body">
        <vxe-toolbar ref="xToolbar" custom style="margin-left: 10px; padding-right: 10px">
          <template #buttons>
            <el-button plain key="set" size="small" type="primary" icon="el-icon-circle-plus" @click="addRow"> 创建土方单位 </el-button>
          </template>
          <template #tools>
            <vxe-pager
              align="left"
              placement="top"
              :total="total"
              :current-page.sync="queryParams.current"
              border
              :page-sizes="[100, 120, 150]"
              size="small"
              :page-size.sync="queryParams.size"
              :layouts="['PrevJump', 'PrevPage', 'Jump', 'NextPage', 'NextJump', 'Sizes', 'Total']"
              @page-change="queryList('0')"
            />
          </template>
        </vxe-toolbar>
      </div>
      <el-row class="form-cls button-cls">
        <el-col :span="24">
          <vxe-table
            id="dataListTable"
            ref="table"
            v-loading="listLoading"
            class="sortable-column-demo"
            :export-config="{}"
            :data="dataList"
            fit
            show-footer
            :height="_tableHeight"
            element-loading-text="加载中"
            border
            stripe
            column-key
            resizable
            highlight-current-row
            :resizable-config="{ minWidth: 50 }"
            :checkbox-config="{ trigger: 'row', highlight: true }"
            :custom-config="{ storage: true }"
            :scroll-y="{ gt: -1 }"
            show-overflow="tooltip"
            show-footer-overflow="tooltip"
            show-header-overflow="tooltip"
            tooltip-effect="dark"
            size="small"
            style="width: 100%"
          >
            <vxe-table-column type="seq" title="序号" width="50" align="center" fixed="left" />
            <vxe-table-column field="name" title="土方单位" sortable align="center" width="200" show-overflow-tooltip />
            <vxe-table-column field="code" title="统一社会信用代码" sortable align="center" width="200" show-overflow-tooltip />
            <vxe-table-column field="phone" title="手机号" sortable align="center" width="250" show-overflow-tooltip />
            <vxe-table-column field="contact" title="联系人" sortable align="center" width="250" show-overflow-tooltip />
            <vxe-table-column field="status" title="冻结状态" sortable align="center" width="140">
              <template slot-scope="scope">
                <el-tag :type="scope.row.status | statusTypeFilter" size="small">
                  {{ scope.row.status | statusFilter }}
                </el-tag>
              </template>
            </vxe-table-column>
            <vxe-table-column field="created" title="创建时间" align="center" width="200" />
            <vxe-table-column field="creater" align="center" title="创建人" sortable width="200" />
            <vxe-table-column align="center" fixed="right" title="操作" width="240">
              <template v-slot="{ row }">
                <span style="color: deepskyblue; cursor: pointer" icon="fa fa-edit" @click="editEvent(row)">编辑</span>—
                <span style="color: red; cursor: pointer" v-if="row.status === 'ENABLE'" icon="fa fa-trash-o" @click="disableEvent(row)">冻结</span>
                <span style="color: darkgreen; cursor: pointer" v-if="row.status === 'DISABLE'" icon="fa fa-trash-o" @click="enableEvent(row)"
                  >解冻</span
                >
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-col>
      </el-row>
    </div>
    <el-dialog :visible.sync="dialogVisible" :title="dialogTitle" width="60%" :close-on-click-modal="false">
      <el-form :model="formData" ref="formData" :rules="rules" label-width="150px">
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="土方单位名称：" prop="name">
              <el-input v-model="formData.name" size="small" class="input-width-8" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="统一社会信用代码：" prop="code">
              <el-input v-model="formData.code" size="small" class="input-width-8" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="联系人：" prop="contact">
              <el-input v-model="formData.contact" size="small" class="input-width-8" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话：" prop="phone">
              <el-input v-model="formData.phone" size="small" class="input-width-8" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitData">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { queryList, companyEdit, companySave, updateStatus } from '@/api/company'
import { getToken } from '@/utils/auth'
import CalTableHeight from '@/mixins/calTableHeight'
import Sortable from 'sortablejs'

export default {
  mixins: [CalTableHeight],
  filters: {
    statusTypeFilter(status) {
      const statusMap = {
        ENABLE: 'success',
        DISABLE: 'danger'
      }
      return statusMap[status]
    },
    statusFilter(status) {
      const statusMap = {
        ENABLE: '正常',
        DISABLE: '冻结'
      }
      return statusMap[status]
    }
  },
  data: function () {
    const validatPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('手机号不能为空'))
      } else {
        const reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('请输入正确的手机号'))
        }
      }
    }

    const validatCreditCode = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('纳税人识别号不能为空'))
      } else {
        const reg = /^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('请输入正确的统一社会信用代码'))
        }
      }
    }
    return {
      total: 0,
      summary: {},
      listLoading: false,
      formData: {
        id: '',
        name: '', //土方单位名称
        code: '', //统一社会信用代码
        contact: '', // 联系人
        phone: '' // 联系方式
      },
      rules: {
        name: [{ required: true, message: '请输入土方单位名称', trigger: 'blur' }],
        code: [
          {
            required: true,
            message: '请输入统一社会信用代码',
            trigger: 'blur'
          },
          {
            validator: validatCreditCode,
            message: '请输入正确的统一社会信用代码',
            trigger: 'blur'
          }
        ],
        contact: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        phone: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },
          {
            validator: validatPhone,
            message: '请输入正确的联系电话',
            trigger: 'blur'
          }
        ]
      },
      // 查询条件
      queryParams: {
        name: '',
        status: '',
        current: 1,
        size: 100
      },
      dataList: [],
      dialogVisible: false,
      dialogVisibleInfo: false,
      dialogTitle: '添加土方单位',
      statusFlags: [
        {
          value: '',
          label: '全部'
        },
        {
          value: 'ENABLE',
          label: '正常'
        },
        {
          value: 'DISABLE',
          label: '冻结'
        }
      ]
    }
  },
  computed: {
    headers() {
      return {
        'lc-token': getToken()
      }
    }
  },
  created() {
    this.$nextTick(() => {
      // 手动将表格和工具栏进行关联,否则列配置将失效
      this.$refs.table.connect(this.$refs.xToolbar)
    })
  },
  activated() {
    this.queryList()
  },
  mounted() {
    // feat: fixed table height
    // table高度初始化，部分模块高度不固定需要等待dom渲染结束再计算
    this.$nextTick(() => {
      this._getTableHeight()
    })
  },
  methods: {
    // feat: fixed table height
    // 返回除table高度外的额外总高度
    _getExtraHeight() {
      return 185 + this.$refs.searchBar.offsetHeight
    },
    // 新增
    addRow() {
      this.resetForm()
      this.dialogTitle = '添加土方单位'
      this.dialogVisible = true
    },
    // 编辑
    editEvent(row) {
      let form = Object.assign(this.formData, row)
      this.formData = form
      this.dialogTitle = '修改土方单位信息'
      this.dialogVisible = true
    },
    // 停用
    disableEvent(row) {
      let params = {
        id: row.id,
        status: 'DISABLE'
      }
      updateStatus(params).then(res => {
        if (res.code === 200) {
          this.$message.success('土方单位冻结成功')
          this.queryList()
        } else {
          this.$message.success(res.msg)
        }
      })
    },
    // 启用
    enableEvent(row) {
      let params = {
        id: row.id,
        status: 'ENABLE'
      }
      updateStatus(params).then(res => {
        if (res.code === 200) {
          this.$message.success('土方单位解冻成功')
          this.queryList()
        } else {
          this.$message.success(res.msg)
        }
      })
    },
    // 行选中
    selectRows() {},
    // 查询
    queryList(flag) {
      this.listLoading = true
      queryList(this.queryParams).then(res => {
        if (res.code === 200) {
          this.dataList = res.data.records
          this.total = res.data.total
        }
        this.listLoading = false
      })
    },
    // 提交数据
    submitData() {
      this.$refs.formData.validate(valid => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            text: '正在保存...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.6)',
            target: document.getElementById('el-dialog')
          })
          if (this.formData.id) {
            companyEdit(this.formData).then(res => {
              if (res.code === 200) {
                loading.close()
                this.$message.success('修改角色信息成功')
                this.resetForm()
                this.queryList()
                this.dialogVisible = false
              } else {
                loading.close()
                this.$message.error(res.msg)
              }
            })
          } else {
            companySave(this.formData).then(res => {
              if (res.code === 200) {
                this.$message.success('新增土方单位成功')
                this.resetForm()
                this.queryList()
                loading.close()
                this.dialogVisible = false
              } else {
                this.$message.error(res.msg)
                loading.close()
              }
            })
          }
        } else {
          return
        }
      })
    },
    // 重置表单
    resetForm() {
      this.formData = {
        id: '',
        name: '',
        code: '',
        contact: '',
        phone: ''
      }
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.input-cls {
  width: 260px;
  font-size: 14px;
  // feat: 搜索栏过多收起
  margin-right: 10px;
}
.icon-cls-green {
  color: #67c23a;
}
.dialog-row {
  margin-top: 10px;
}
</style>

<style rel="stylesheet/scss" lang="scss">
.validFieldClass .el-input__inner {
  border-color: #f56c6c;
}
.el-pagination {
  float: right;
}
.sortable-column-demo .vxe-header--row .vxe-header--column.sortable-ghost,
.sortable-column-demo .vxe-header--row .vxe-header--column.sortable-chosen {
  background-color: #dfecfb;
}
.vxe-table--footer .col-blue {
  background: #f2f5f0;
}
/*单号复制样式 开始*/
.copy-content {
  right: 0.1em;
  top: 0.1em;
  float: right !important;
  position: absolute !important;
}
.copy-content:hover {
  background-color: #1482f0;
  float: right !important;
  height: 1.8em;
  width: 1.8em;
}
.write-color {
  border-color: #f0f0f0;
}
/*单号复制样式 结束*/
.input-width-6 {
  width: 60% !important;
}
.input-width-7 {
  width: 70% !important;
}
.input-width-8 {
  width: 80% !important;
}
</style>
