import request from '@/utils/http'

// 查询信息
export function queryList(params) {
  return request({
    url: '/company/findByPage',
    method: 'get',
    params
  })
}

// 修改土方单位信息
export function companyEdit(params) {
  return request({
    url: '/company/edit',
    method: 'post',
    data: params
  })
}

// 新增土方单位信息
export function companySave(params) {
  return request({
    url: '/company/save',
    method: 'post',
    data: params
  })
}

// 编辑状态
export function updateStatus(params) {
  return request({
    url: '/company/status',
    method: 'get',
    params
  })
}

// 查询工地列表
export function getProjectList(params) {
  return request({
    url: '/company/findByPage',
    method: 'get',
    params
  })
}
